header {
    background: rgb(14,0,36);
    background: linear-gradient(
        180deg,
        rgba(14,0,36,1) 0%,
        rgba(121,9,110,1) 40%,
        rgba(155,5,173,1) 60%,
        rgba(199,0,255,0) 100%
    );
    padding-bottom: 2rem;
    padding-top: .25rem;
}

h1 {
    color:violet;
    font-family: 'Lobster', cursive;
    text-align: center;
    font-size: clamp(3rem, 2.6170rem + 1.7021vw, 4rem);
    text-shadow: 4px 4px 16px rgb(15, 15, 70);
    margin-top: .5rem;
    margin-bottom: 0;
}

ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ul li {
    text-align: center;
    flex-basis: 100%;
}

ul li .btn-link {
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: inline;
    cursor: pointer;
}
ul li p {
    font-size: clamp(1rem, 0.0102rem + 2.4490vw, 1.5rem);
    color: violet;
    text-shadow: 0px -4px 16px rgb(15, 15, 70);
    font-weight: bold;
    transition: color .25s ease-out;
    margin-bottom: .4rem;
}
ul li p:hover,
ul li.active p {
    color: rgb(248, 232, 255);
}

.underline {
    position: relative;
    width: 110%;
    top: -1px;
    right: 5%;
    height: 2px;
    border-bottom: 2px solid rgb(248, 232, 255);;
}

@media only screen and (min-width: 1300px) {
    header {
        padding-bottom: .8rem;
    }

    ul li .btn-link {
        transform: translateY(-.7rem);
    }

    h1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
