.container-commission-page {
    overflow-x: hidden;
}

.comm-section {
    display: flex;
    justify-content: center;
}

/* OVERVIEW SECTION */
.container-overview {
    background: rgba(56, 45, 129, 0.8);
    padding: 0 .4rem;
    margin: .2rem .2rem .8rem .2rem;
    border-radius: .4rem;
    border: 1px solid violet;
    width: min(97%, 80rem);
    box-shadow: 0 0 5px 5px #150f2b;
}
.container-overview p, b {
    margin-top: .3rem;
    font-size: clamp(1.02rem, 0.6447rem + 1.6681vw, 2rem);
    line-height: clamp(1rem, 0.6170rem + 1.7021vw, 2rem);
}
.container-overview .text-comm-spaces {
    text-align: center;
    margin-bottom: .5rem;
}

/* SELECT TYPE SECTION */
.container-type-btns {
    margin: 0 .2rem .4rem .2rem;
    padding: .5rem 0 .4rem 0;
    border-radius: .4rem;
    border: 1px solid violet;
    background: rgba(56, 45, 129, 0.8);
    box-shadow: 0 0 5px 5px #150f2b;
}
.radio-btn-container {
    display: flex;
    align-items: center;
    gap: .3rem;
    padding: 0 2rem;
    margin-bottom: .7rem;
}
.radio-btn-container .radio-text {
    font-size: clamp(1.2rem, 0.2250rem + 4.3333vw, 2.5rem);
    line-height: clamp(1.2rem, 0.2250rem + 4.3333vw, 2.5rem);
    margin-top: .5rem;
    width: 80%;
}
.radio-btn-container .custom-radio-btn {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 2px solid violet;
    border-radius: 50%;
    background-color: rgb(105, 89, 204, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}
.radio-btn-container .custom-radio-btn input {
    display: none;
}
.radio-btn-container .custom-radio-btn .radio-dot {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: rgb(206, 121, 228);
    border-radius: 50%;
    display: inline-block;
    transform: scale(0);
    opacity: 0;
    transition: all .2s ease-out;
}
.radio-btn-container .custom-radio-btn input:checked + .radio-dot {
    transform: scale(1);
    opacity: 1;
}
.container-type-btns .radio-btn-container .custom-radio-btn {
    transform: translateY(.3rem);
}

.container-ex-img {
    margin: 0 .2rem .8rem .2rem;
    padding: .5rem .6rem .6rem .6rem;
    border-radius: .4rem;
    border: 1px solid violet;
    background: rgba(56, 45, 129, 0.8);
    box-shadow: 0 0 5px 5px #150f2b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-ex-img b {
    font-size: clamp(1.2rem, 0.5250rem + 3.0000vw, 2.1rem);
    margin-bottom: .4rem;
    text-align: left;
    align-self: flex-start;
}
.container-ex-img img {
    border-radius: .4rem;
    width: 100%;
    height: auto;
}

.price {
    font-weight: bold;
    text-decoration: underline;
}

/* VISUAL DESCRIPTION SECTION */
.container-vis-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .3rem;
    background: rgba(56, 45, 129, 0.8);
    border: 1px solid violet;
    box-shadow: 0 0 5px 5px #150f2b;
    margin: 0 .4rem;
    margin-bottom: .8rem;
    padding: .6rem .8rem;
    border-radius: .4rem;
    width: min(90vw, 75rem);
    margin: 0 auto .6rem auto;
}

.container-vis-description label {
    align-self: flex-start;
    margin-bottom: .3rem;
    font-weight: bold;
    font-size: clamp(1.1rem, 0.5750rem + 2.3333vw, 1.8rem);
}
.container-vis-description textarea {
    border-radius: .5rem;
    border: 2px solid violet;
    padding: .3rem .2rem;
    background: slateblue;
    color: violet;
    font-weight: bold;
    width: 100%;
    min-height: 7rem;
    font-size: clamp(0.8rem, 0.5375rem + 1.1667vw, 1.15rem);
}

.container-vis-description textarea::placeholder {
    color: rgb(233, 181, 233);
    font-size: clamp(0.8rem, 0.5375rem + 1.1667vw, 1.15rem);
}
.container-vis-description textarea:-ms-input-placeholder {
    color: rgb(233, 181, 233);
    font-size: clamp(0.8rem, 0.5375rem + 1.1667vw, 1.15rem);
}
.container-vis-description textarea::-ms-input-placeholder {
    color: rgb(233, 181, 233);
    font-size: clamp(0.8rem, 0.5375rem + 1.1667vw, 1.15rem);
}
.container-vis-description textarea.warning::placeholder {
    color: rgb(235, 93, 112);
}
.container-vis-description textarea.warning:-ms-input-placeholder {
    color: rgb(235, 93, 112);
}
.container-vis-description textarea.warning::-ms-input-placeholder {
    color: rgb(235, 93, 112);
}

.container-vis-description textarea.warning {
    animation: shake .82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-2px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(4px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-8px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(8px, 0, 0);
    }
}

.container-upload-imgs {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid violet;
    background: rgba(56, 45, 129, 0.8);
    box-shadow: 0 0 5px 5px #150f2b;
    color: violet;
    margin: 0 .4rem;
    margin-bottom: .8rem;
    padding: .6rem .8rem;
    border-radius: .4rem;
    width: min(90vw, 75rem);
    margin: 0 auto .6rem auto;
    max-height: 8.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
}
.container-upload-imgs button {
    font-size: clamp(1.2rem, 0.9750rem + 1.0000vw, 1.5rem) !important;
}

.container-files {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.container-upload-imgs .file-container {
    border-radius: .1rem;
    border: 2px solid rgb(214, 73, 184);
    padding: .25rem .5rem;
    height: 2rem;
    background: rgba(81, 66, 179, 0.9);
    color: rgb(214, 73, 184);
    margin-top: .5rem;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.container-upload-imgs .file-container b {
    inline-size: 80%;
    overflow-wrap: break-word;
    transform: translateY(-.1rem);
}
.container-upload-imgs .file-container span {
    color: rgb(238, 130, 153);
    font-weight: 900;
    font-size: 1.5rem;
    vertical-align: center;
    margin-left: .8rem;
    cursor: pointer;
}

/* BACKGROUND OPTIONS */
.container-bg-btns {
    margin: .3rem .2rem .8rem .2rem;;
    padding: .5rem 0 .4rem 0;
    border-radius: .4rem;
    border: 1px solid violet;
    background: rgba(56, 45, 129, 0.8);
    box-shadow: 0 0 5px 5px #150f2b;
    max-height: 50vh;
    width: 90vw;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.container-bg-btns > div {
    transform: translateX(5%);
    display: flex;
    flex-wrap: wrap;
}
.container-bg-btns .radio-btn-container {
    padding: 0;
    flex-basis: 50%;
}
.container-bg-btns .radio-btn-container .custom-radio-btn {
    transform: translateY(.4rem);
}

/* FINALISE FORM */
.comm-finalise {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-finalise {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .4rem;
    width: min(90vw, 75rem);
    margin: .3rem .2rem .8rem .2rem;
    padding: .5rem;
    border-radius: .4rem;
    border: 1px solid violet;
    background: rgba(56, 45, 129, 0.8);
    box-shadow: 0 0 5px 5px #150f2b;
}
.container-finalise .form-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .25rem;
}
.container-finalise .form-section label {
    font-size: clamp(1.1rem, 0.9277rem + 0.7660vw, 1.55rem);
    font-weight: bold;
}
.container-finalise input[type="text"],
.container-finalise textarea {
    border-radius: .5rem;
    border: 2px solid hotpink;
    padding: .3rem .2rem;
    background: slateblue;
    color: violet;
    font-weight: bold;
    font-size: clamp(0.8rem, 0.5375rem + 1.1667vw, 1.15rem);
}
.container-finalise textarea {
    min-height: 5rem;
}
.container-finalise input::placeholder {
    color: rgb(233, 181, 233);
    font-size: clamp(0.8rem, 0.5375rem + 1.1667vw, 1.15rem);
}
.container-finalise input:-ms-input-placeholder {
    color: rgb(233, 181, 233);
    font-size: clamp(0.8rem, 0.5375rem + 1.1667vw, 1.15rem);
}
.container-finalise input::-ms-input-placeholder {
    color: rgb(233, 181, 233);
    font-size: clamp(0.8rem, 0.5375rem + 1.1667vw, 1.15rem);
}
.container-finalise input.warning::placeholder {
    color: rgb(235, 93, 112);
}
.container-finalise input.warning:-ms-input-placeholder {
    color: rgb(235, 93, 112);
}
.container-finalise input.warning::-ms-input-placeholder {
    color: rgb(235, 93, 112);
}

.container-finalise input.warning {
    animation: shake .82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px
}

.container-finalise.permission-section {
    margin-bottom: .6rem;
    padding-bottom: 1rem;
}
.container-finalise .radio-btn-container {
    padding-left: 1rem;
    padding-right: 0;
    margin-bottom: 0;
}
.container-finalise .custom-radio-btn {
    margin-right: .5rem;
}
.container-finalise .radio-text {
    width: 100%;
    font-size: clamp(1.2rem, 0.6000rem + 2.6667vw, 2rem);
}
.container-finalise .radio-btn-container .custom-radio-btn {
    border-radius: .3rem;
}
.container-finalise .radio-btn-container .custom-radio-btn .checkmark {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: rgb(177, 102, 211);
    display: inline-block;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    opacity: 0;
    transition: all .2s ease-out;
}
.container-finalise .radio-btn-container .custom-radio-btn input:checked + .checkmark {
    transform: scale(1);
    opacity: 1;
}

.comm-finalise .request-status {
    text-align: center;
    margin-bottom: .6rem;
    font-size: clamp(1.05rem, 0.7628rem + 1.2766vw, 1.8rem);
    color: rgb(221, 136, 136);
    width: min(90vw, 75rem);
}
.comm-finalise .request-status.success {
    color: rgb(197, 245, 240);
    width: 100%;
}
.comm-finalise .request-status.failed {
    color: rgb(221, 136, 136);
    width: 100%;
}

/* NAVIGATION BUTTONS */
.navigation-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: .5rem;
    gap: .75rem;
}
.container-upload-imgs button,
.navigation-btns button {
    background: slateblue;
    border: 2px solid violet;
    border-radius: .3rem;
    color: violet;
    font-weight: 500;
    font-size: clamp(1.4rem, 0.8000rem + 2.6667vw, 2.2rem);
    padding: .4rem .8rem;
    cursor: pointer;
}

.container-commission-page .container-lds-cat p {
    margin-top: .1rem;
}
.container-commission-page .container-lds-cat {
    margin-top: .75rem;
}

@media only screen and (min-width: 650px) {
    /* COMMISSION TYPE */
    .container-comm-type {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container-ex-img {
        padding: 0 .5rem;
        padding-bottom: .5rem;
        width: 60%;
    }

    .container-ex-img img {
        height: 50vh;
        width: auto;
    }

    /* BACKGROUND OPTIONS */
    .container-bg-btns {
        overflow-y: hidden;
        width: 70vw;
    }
    .container-bg-btns .radio-btn-container {
        flex-basis: 20%;
    }
}

@media only screen and (min-width: 1300px) {
    /* OVERVIEW SECTION */
    .container-overview {
        margin: 1.5rem 0;
        padding: 1rem 1.5rem;
        border-radius: 1.2rem;
        border: 2px solid violet;
    }

    .container-overview .text-comm-spaces {
        margin-bottom: 1.3rem;
    }

    /* SELECT TYPE SECTION */
    .radio-btn-container {
        margin-bottom: 1.75rem;
    }
    .container-type-btns {
        width: 100%;
    }

    .radio-btn-container .radio-text {
        margin-top: 1rem;
    }
    .container-finalise .radio-btn-container .radio-text {
        margin-top: .25rem;
    }
    .container-finalise .form-section label {
        margin-top: .3rem;
    }

    .container-comm-type {
        flex-direction: row;
        align-items: flex-start;
        gap: .75rem;
        margin-bottom: .5rem;
    }

    .container-type-btns {
        width: 40vw;
        border: 2px solid violet;
    }
    .container-ex-img {
        width: 50vh;
        padding: .5rem 1rem;
        padding-top: .25rem;
        border: 2px solid violet;
        margin: 0;
    }
    .container-ex-img img {
        border-radius: .4rem;
        width: 100%;
        height: auto;
    }
    .container-ex-img b {
        margin-bottom: .8rem;
    }

    .radio-btn-container {
        padding-right: 0;
    }

    .container-type-btns .radio-btn-container:first-child .custom-radio-btn,
    .container-type-btns .radio-btn-container .custom-radio-btn {
        transform: translateY(.7rem);
    }

    /* VISUAL DESCRIPTION */
    .container-vis-description {
        gap: .6rem;
        padding-bottom: 1.5rem;
    }
    .container-vis-description label {
        margin-left: 7.5%;
    }
    .container-vis-description textarea {
        max-width: 85%;
    }
    .container-upload-imgs {
        padding: 1rem .6rem;
        margin: 1.25rem 0;
        max-height: 20rem;
    }
    .container-upload-imgs .container-files {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: .5rem;
        width: 60%;
        margin-top: .5rem;
    }
    .container-files {
        flex-direction: row;
    }
    .container-upload-imgs .file-container {
        width: unset;
    }
    .container-upload-imgs .file-container b {
        inline-size: unset;
    }

    /* BACKGROUND OPTIONS */
    .container-bg-btns {
        width: 60vw;
        padding-bottom: .3rem;
        margin: 1rem 0 1.5rem 0;
    }
    .container-bg-btns .radio-btn-container .custom-radio-btn {
        transform: translateY(.4rem);
    }

    /* FINALISE SECTION */
    .form-section.input-section {
        flex-direction: row;
    }
    .input-section label {
        width: 15%;
    }
    .input-section input[type="text"] {
        width: 85%;
        margin-bottom: 1.5rem;
        padding: .6rem .4rem;
    }
    .form-section textarea {
        margin-top: .35rem;
    }
    .container-finalise {
        padding: 1rem;
        margin: 1.25rem 0 1.75rem;
    }
    .container-finalise .custom-radio-btn  {
        transform: translateY(.4rem);
    }

    .comm-finalise .container-finalise:first-child {
        margin-bottom: 0;
    }

    .container-finalise.permission-section {
        margin-bottom: .75rem;
    }
    .comm-finalise .request-status {
        margin-bottom: 1.2rem;
    }

    .comm-finalise .request-status.success {
        width: 50%;
    }
    .comm-finalise .request-status.failed {
        width: 50%;
    }

    /* NAVIGATION BUTTONS */
    .navigation-btns button {
        padding: .4rem .8rem;
    }
}
