.work-section {
    padding: 0 .8rem;
}

.container-title {
    margin-bottom: .7rem;
    display: flex;
    line-height: 1rem;
    align-items: center;
    justify-content: center;
}
.container-title h2 {
    text-align: center;
    margin: auto;
    transform: translateX(-25%);
}

.container-load-more {
    width: 100%;
    display: flex;
    justify-content: center;
}
.container-title button,
.container-load-more button {
    margin-right: auto;
    background: slateblue;
    border: 2px solid rgb(50, 39, 122);
    border-radius: .3rem;
    color: violet;
    font-size: .9rem;
    padding: .2rem .1rem;
    width: 5rem;
    height: 2.9rem;
    cursor: pointer;
}

.container-load-more button {
    margin-right: unset;
    margin-bottom: .8rem;
}

.container-toggle-filters {
    position: relative;
    z-index: 1;
}

.container-columns {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

.container-image-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media only screen and (min-width: 370px) {
    .container-title button {
        font-size: 1rem;
        padding: .3rem .4rem;
        width: 7rem;
    }
}

@media only screen and (min-width: 700px) {
    .work-section {
        padding: 0 1.2rem;
    }

    .container-columns {
        gap: 1.2rem;
    }

    .container-image-column {
        width: calc(50% - 1.2rem);
    }
}

@media only screen and (min-width: 768px) {
    .container-title h2 {
        margin: unset;
        margin-bottom: 1.75rem;
        color: violet;
        text-align: center;
        margin-right: unset;
        transform: unset;
    }
}

@media only screen and (min-width: 1300px) {
    .work-section {
        padding: 0 2rem;
    }

    .container-columns {
        gap: 2rem;
    }

    .container-image-column {
        width: calc(33% - 2rem);
    }
}
