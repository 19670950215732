.backdrop {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #1a1a1af6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
}
