.burger-btn {
    width: 60px;
    height: 45px;
    position: relative;
    margin: 0.6rem 0.5rem 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.burger-btn span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: slateblue;
    border-radius: 6px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.burger-btn span:nth-child(1) {
    top: 4px;
}

.burger-btn span:nth-child(2) {
    top: 18px;
}

.burger-btn span:nth-child(3) {
    top: 32px;
}

.burger-btn.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.burger-btn.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.burger-btn.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
