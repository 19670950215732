html {
  overflow-y: scroll;
  width: 100vw;
  overflow-x: hidden;
}

body > iframe {
  pointer-events: none;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  background-image: url('./bg-v4.gif');
  background-color: darkgray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-family: 'Lobster', cursive;
  color: violet;
  text-shadow: 0px 0px 16px black;
}

p, b, label, button {
  font-family: 'Source Sans Pro', sans-serif;
  color: violet
}
h2 {
  margin-top: .3rem;
  font-size: clamp(2rem, 1.6170rem + 1.7021vw, 3rem);
  font-weight: 400;
  text-align: center;
  margin-bottom: .7rem;
}
