input[type="text"].warning::placeholder,
textarea.warning::placeholder {
    color: rgb(235, 93, 112);
    opacity: 1;
}

input[type="text"].warning:-ms-input-placeholder,
textarea.warning:-ms-input-placeholder {
    color: rgb(235, 93, 112);
}

input[type="text"].warning::-ms-input-placeholder,
textarea.warning::-ms-input-placeholder {
    color: rgb(235, 93, 112);
}
::placeholder {
    color: rgb(233, 181, 233);
    opacity: 1;
}

:-ms-input-placeholder {
    color: rgb(233, 181, 233);
}

::-ms-input-placeholder {
    color: rgb(233, 181, 233);
}

.container-form {
    display: flex;
    justify-content: center;
}

.container-form form {
    border: 2px solid violet;
    background: rgba(56, 45, 129, 0.8);
    padding: .35rem;
    border-radius: .3rem;
    width: min(90%, 50rem);
}

.input-block,
.area-block {
    display: flex;
    flex-direction: column;
    margin-bottom: .3rem;
}
.input-block label,
.area-block label {
    text-align: left;
    margin-bottom: .2rem;
    font-size: clamp(1.1rem, 0.9277rem + 0.7660vw, 1.55rem);
    font-weight: bold;
}
.input-block input[type="text"],
.area-block textarea {
    border-radius: .5rem;
    border: 2px solid hotpink;
    padding: .3rem .2rem;
    background: slateblue;
    color: violet;
    font-weight: bold;
}

.input-block input[type="text"].warning,
.area-block textarea.warning {
    animation: shake .82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-2px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(4px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-8px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(8px, 0, 0);
    }
}

.container-captcha-submit {
    display: flex;
    justify-content: center;
}

.container-recaptcha {
    transform: scale(0.77) translateX(16%);
    -webkit-transform: scale(0.77) translateX(16%);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
}

.container-btn {
    margin-top: .75rem;
    width: 100%;
}
.container-btn .container-captcha-submit button {
    display: block;
    margin: 0 auto;
    margin-bottom: .35rem;
    padding: .3rem .5rem;
    background: slateblue;
    color: violet;
    border: 2px solid hotpink;
    border-radius: .3rem;
    transition: all .3s ease-out;
    cursor: pointer;
    font-size: 1.25rem;
}
.container-btn .container-captcha-submit button:hover {
    background: hotpink;
    color: darkmagenta;
}

.lds-outer-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.text-error {
    text-align: center;
    font-size: clamp(1.1rem, 0.8319rem + 1.1915vw, 1.8rem);
    margin-top: .5rem;
    margin-bottom: .5rem;
}
.text-error b {
    color: rgb(235, 93, 112);
}

.text-success {
    text-align: center;
    font-size: clamp(1.1rem, 0.8319rem + 1.1915vw, 1.8rem);
    margin-top: .5rem;
    margin-bottom: .5rem;
}
.text-success b {
    color: rgb(197, 245, 240);
}


@media only screen and (min-width: 900px) {
    .container-form form {
        padding: 1.5rem;
        border-radius: .75rem;
    }

    .input-block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: .3rem;
    }
    .input-block label {
        width: 18%;
        text-align: left;
        font-weight: bold;
        margin-bottom: 0;
        margin-top: .15rem;
    }
    .input-block input[type="text"] {
        width: 82%;
        margin-bottom: 1.5rem;
        padding: .6rem .4rem;
        font-size: large;
    }

    .area-block label {
        align-self: flex-start;
    }
    .area-block textarea {
        min-height: 7rem;
        font-size: 1.25rem;
    }

    .container-recaptcha {
        transform: unset;
        -webkit-transform: unset;
        transform-origin: unset;
        -webkit-transform-origin: unset;
    }

    .container-btn button {
        padding: .8rem 1rem;
        margin-top: 1.4rem;
        font-weight: bold;
        font-size: large;
    }
}
