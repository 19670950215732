.container-about {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-text {
    margin-top: .75rem;
    margin-bottom: .75rem;
    width: min(90vw, 75rem);
    font-size: clamp(1.2rem, 0.7404rem + 2.0426vw, 2.4rem);
    background: rgba(56, 45, 129, 0.8);
    border: 1px solid violet;
    border-radius: .4rem;
    padding: 0 .5rem;
}

.container-text p {
    margin-top: .5rem;
    margin-bottom: .75rem;
}

@media only screen and (min-width: 1300px) {
    .container-text {
        padding: 1rem;
    }

    .container-text p {
        margin: 0;
        margin-bottom: .5rem;
    }
}
