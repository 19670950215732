.container-lds-cat {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-lds-cat p {
    text-align: center;
    font-size: clamp(1.1rem, 0.5750rem + 2.3333vw, 1.8rem);
}

.container-lds-cat img {
    margin: 0;
}
