.container-img-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-img-modal .btn-modal {
    position: absolute;
    z-index: 11;
    margin: .5rem;
    padding: .8rem 1.1rem;
    background: #be87c9;
    font-size: 1.3rem;
    font-weight: 600;
    color: #1e1a3b;
    border: 2px solid #483f8d;
    border-radius: 1rem;
    cursor: pointer;
    transition: all .2s ease-out;
}
.container-img-modal .container-lds {
    position: absolute;
    z-index: 11;
    margin: .5rem;
    height: 4rem;
}
.container-img-modal .container-lds .container-lds-cat {
    display: block;
}
.container-img-modal .container-lds img {
    background: slateblue;
    border-radius: 100%;
}

.container-img-modal .btn-left {
    bottom: 0;
    left: 0;
}
.container-img-modal .btn-right,
.container-img-modal .container-lds {
    bottom: 0;
    right: 0;
}
.container-img-modal .btn-close {
    top: 0;
    right: 0;
    padding: .4rem .8rem;
}

.container-description {
    position: absolute;
    border-radius: .3rem;
    background:rgba(20, 17, 49, 0);
    height: 100%;
    display: flex;
    align-items: center;
    transition: background .25s ease-in-out;
}
.container-description.active {
    background: rgba(20, 17, 49, 0.75);
}
.container-description p {
    width: 80%;
    text-align: center;
    transform: translateX(10%);
    font-size: clamp(1rem, 0.8851rem + 0.5106vw, 1.5rem);
    line-height: 1.5rem;
    color: rgb(238, 130, 238, 0);
    transition: color .25s ease-in-out;
}
.container-description.active p {
    color: rgb(238, 130, 238);
}

h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    color: violet;
    text-align: center;
    font-size: clamp(1.5rem, 1.2638rem + 2.3830vw, 2.3rem);
}

.container-img-modal .container-img img {
    max-width: clamp(60vw, 700px, 90vw);
    max-height: 85vh;
    width: auto;
    height: 100%;
    border-radius: .3rem;
    cursor: auto;
    margin-bottom: 3rem;
    cursor: pointer;
}

@media only screen and (min-width: 900px) {
    .container-img-modal {
        width: 100%;
        height: 100%;
        justify-content: space-evenly;
    }

    .container-img-modal .container-lds {
        position: absolute;
        z-index: 11;
        width: 100%;
        height: 100%;
    }
    .container-img-modal .container-lds .container-lds-cat {
        display: flex;
        justify-content: center;
    }
    .container-img-modal .container-lds img {
        background: transparent;
        height: 8rem;
    }

    .container-img-modal .btn-modal,
    .container-img-modal .container-lds {
        position: relative;
        padding: 1.2rem 1.5rem;
    }

    .container-img-modal .btn-left {
        bottom: auto;
        left: auto;
    }
    .container-img-modal .btn-right,
    .container-img-modal .container-lds {
        bottom: auto;
        right: auto;
    }

    .container-img-modal .btn-left,
    .container-img-modal .btn-right {
        border-radius: 3rem;
        border: none;
        width: 100%;
        height: 100%;
        background: #1a1a1a00;
        color: darkslategray;
        font-size: 5rem;
    }
    .container-img-modal .btn-left:hover,
    .container-img-modal .btn-right:hover {
        background: #1a1a1a00;
        color: rgb(134, 65, 225);
    }
    .container-img-modal .btn-left:disabled,
    .container-img-modal .btn-right:disabled {
        background: #1a1a1a00;
        color: darkslategray;
    }

    .container-img-modal .btn-close {
        position: absolute;
        top: 0;
        right: 1.4rem;
        width: unset;
        height: unset;
        margin: unset;
        padding: 1.2rem 2rem;
        border-radius: .3rem;
        border: none;
        background: none;
        color: darkslategray;
        font-weight: 900;
        font-size: 4em;
        z-index: 12;
    }
    .container-img-modal .btn-close:hover {
        background: none;
        color: violet;
    }

    .container-img-modal .container-img img {
        margin-bottom: 0;
    }

    .container-description {
        z-index: 13;
    }
    .container-description p {
        line-height: 2rem;
    }
    .container-description:hover {
        background:rgba(20, 17, 49, 0.75);
    }
    .container-description:hover p {
        color: rgb(238, 130, 238);
    }
}
